<template>
  <section ref="section" v-viewport.debounce:250="onResize">
    <div class="bg-1 -below"></div>
    <div class="bg-2 -below"></div>
    <!-- Gallery -->
    <div ref="swiper" class="swiper-container -above">
      <div class="swiper-wrapper" ref="swiperWrapper">
        <div
          v-for="(slide, i) in gallery"
          :key="i"
          class="swiper-slide"
        >
          <component :is="slide.to ? 'router-link' : 'div'"
            :to="slide.to ? { name: 'CollectionPage', params: { slug: slide.to }} : null"
            class="slide-content text-decoration-none"
          >
            <div ref="title" v-if="slideCaption"  class="index mb-6">
              <template v-if="slide.title || slide.subtitle">
                <h3 class="h4" v-if="slide.title">{{ slide.title }}</h3>
                <h4 class="h6" v-if="slide.subtitle">{{ slide.subtitle }}</h4>
              </template>
              <span v-else class="index text-small">{{ getSlideIndex(i) }}</span>
            </div>
            <div ref="imageContainer" class="image-container">
              <v-img
                ref="image"
                class="image"
                width="100%"
                maxWidth="100%"
                :height="$vuetify.breakpoint.mdAndUp ? '400px' : '300px'"
                :maxHeight="$vuetify.breakpoint.mdAndUp ? '400px' : '300px'"
                :lazy-src="getLazySrc(slide.image)"
                :src="optimizedImageURL(slide.image, { width: 800, quality: 95 })"
                :aspect-ratio="getAspectRatio(slide.image)"
                contain
                transition="fade-transition"
              />
              <Arrow class="link-arrow" v-if="slide.to"/>
            </div>
          </component>
        </div>
      </div>
    </div>
    <!-- Gallery controls -->
    <v-container v-show="gallery.length > 1">
      <v-row>
        <v-col
          cols="12"
          md="8"
          offset-md="2"
          class="d-flex align-center justify-center mt-4 mt-md-9"
        >
          <SliderProgress
            ref="sliderProgress"
            :type="progressType"
            :slides="gallery.length"
          />
          <SliderArrows
            class="slider-arrow"
            :nextDisabled="nextDisabled"
            :prevDisabled="prevDisabled"
            @prev="slidePrev()"
            @next="slideNext()"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { Swiper } from 'swiper'
import { gsap } from 'gsap'
import ScrollAppear from '@/animations/gsap/scroll-appear'
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'

import Arrow from '@/assets/img/cta-arrow.svg'
import SliderProgress from '@/atoms/SliderProgress'
import SliderArrows from '@/atoms/SliderArrows'
/**
 * Maps a value between two ranges
 * @param  {Number} n       Value to map
 * @param  {Number} min1    Source range minimum
 * @param  {Number} max1    Source range maximum
 * @param  {Number} min2    Target range minimum
 * @param  {Number} max2    Target range maximum
 * @return {Number}         Mapped value
 */
function map (n, min1, max1, min2, max2) {
  return (n - min1) * (max2 - min2) / (max1 - min1) + min2
}

/**
 * Limit a value between a min and a max (inclusive)
 * @param  {Number} n   Value to cap
 * @param  {Number} min Minimum possible value
 * @param  {Number} max Maximum possible value
 * @return {Number}     Capped value
 */
function cap (n, min, max) {
  return Math.min(Math.max(n, min), max)
}

export default {
  name: 'gallery',
  props: {
    slides: {
      type: Array,
      required: false
    },
    images: {
      type: Array,
      required: false
    },
    progressType: {
      type: String,
      default: 'slide' // 'full' or 'slide'
    },
    slideCaption: {
      type: Boolean,
      required: false,
      default: true
    },
    slidesPerView: {
      type: Number,
      required: false
    }
  },
  components: { SliderProgress, SliderArrows, Arrow },
  data () {
    const gallery = (this.slides || this.images)
      .map(slide => {
        if (slide.detail) {
          return { ...slide.detail, title: slide.title }
        }
        return slide
      })
      .map(slide => {
        const image = { ...(slide.image || slide) }
        image.meta = JSON.parse(image.metaData)
        image.meta.ratio = image.meta['image-width'] / image.meta['image-height']
        return { image, title: slide.title, subtitle: slide.subtitle, to: slide._slug }
      })

    return {
      gallery: Object.freeze(gallery),
      nextDisabled: false,
      prevDisabled: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      // this.createTimelines()
      this.appearTl = ScrollAppear(this.$refs.section)
      this.tls = []
      this.currentTransitionSpeed = 0
      this.swiper = new Swiper(this.$refs.swiper, {
        allowTouchMove: this.gallery.length > 1,
        centeredSlides: true,
        slidesPerView: this.slidesPerView ? this.slidesPerView : 1.5,
        spaceBetween: 15,
        freeMode: true,
        freeModeSticky: true,
        watchSlidesProgress: true,
        updateOnWindowResize: false,
        grabCursor: true,
        virtualTranslate: true,
        speed: 0,
        on: {
          init: this.setTranslate,
          setTranslate: this.setTranslate,
          reachBeginning: () => {
            this.prevDisabled = true
            this.nextDisabled = false
          },
          reachEnd: () => {
            this.prevDisabled = false
            this.nextDisabled = true
          },
          fromEdge: this.fromEdge
        }
      })
    })
  },
  beforeDestroy () {
    this.swiper && this.swiper.destroy(true, false)
    this.appearTl.kill()
    if (this.tls.length) {
      this.tls.forEach(tl => tl.kill())
      this.tls.length = 0
    }
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio,
    onResize () {
      if (this.swiper) {
        this.swiper.update()
      }
    },
    fromEdge (swiper) {
      if (!swiper.isBeginning && !swiper.isEnd) {
        this.prevDisabled = false
        this.nextDisabled = false
      }
    },
    slidePrev () {
      this.swiper && this.swiper.slidePrev()
    },
    slideNext () {
      this.swiper && this.swiper.slideNext()
    },
    getSlideIndex (i) {
      return i < 9 ? `0${i + 1}` : i + 1
    },
    setTranslate (swiper, _wrapperTranslate) {
      const wrapperTranslate = _wrapperTranslate || swiper.translate
      if (wrapperTranslate === undefined) return
      const duration = cap(
        map(Math.abs(wrapperTranslate - swiper.previousTranslate), 0, window.innerWidth, 0, 0.75),
        0.2,
        0.75
      )
      const slideW = swiper.slidesSizesGrid[0]

      if (this.tls.length) {
        this.tls.forEach(tl => tl.kill())
        this.tls.length = 0
      }
      this.tls.push(gsap.to(this.$refs.swiperWrapper, {
        duration,
        x: -cap(-wrapperTranslate, swiper.snapGrid[0], swiper.snapGrid[swiper.snapGrid.length - 1]),
        ease: 'power2.easeOut'
      }))
      this.$refs.sliderProgress.setProgress(swiper.progress, duration)

      // do magic with each slide
      swiper.slides.forEach((slide, i) => {
        const normProgress = Math.max(0, Math.min(1, (slide.progress + 1) / 2))
        const imageCont = this.$refs.imageContainer[i]
        const image = this.$refs.image[i].$el
        const imageW = Math.min(slideW, 400) * this.gallery[i].image.meta.ratio
        const opacity = normProgress < 0.5 ? 1 : 1 - (normProgress - 0.5) * 2
        let x = 0

        if (slideW > imageW) {
          x = (slideW - imageW) * normProgress
        }

        this.$refs.title && this.tls.push(gsap.to(this.$refs.title[i], { duration, x: x / 2, opacity, ease: 'power2.easeOut' }))
        this.tls.push(gsap.to(image, { duration, x, ease: 'power2.easeOut' }))
        this.tls.push(gsap.to(imageCont, { duration, opacity, ease: 'power2.easeOut' }))

        // if (duration) {
        // } else {
        //   gsap.set(this.$refs.title[i], { x: x / 2, opacity })
        //   gsap.set(image, { x, opacity })
        // }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-slide {
  height: auto;
}

.slide-content {
  cursor: inherit;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.slider-arrow {
  width: rem(170px);
  padding-left: rem(48px);
}

.index {
  display: block;

  > * {
    width: 80%;
  }
}

.bg-1,
.bg-2 {
  position: absolute;
  right: auto;
  left: 0;
  top: 50%;
  width: rem(500px);
  height: rem(500px);
  background: $bg-emo-gradient-3;

  // transform: translate(0%, -50%);
  pointer-events: none;
}

.bg-1 {
  background: $bg-emo-gradient-1;

  transform: translate(0%, -50%);
}

.bg-2 {
  left: rem(250px);
}

.image-container {
  position: relative;
}

.image {
  transition: opacity 0.4s;
  @include breakpoint('md-and-up') {
    a.slide-content:hover & {
      opacity: 0.5;
    }
  }

  &::v-deep .v-image__image {
    background-position: left center !important;
  }
}

.link-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: rem(-30px);
  margin-left: rem(-30px);
  opacity: 0;
  transition: opacity 0.4s;
  width: rem(60px);
  height: rem(60px);
  color: $color-text-dark;
  stroke-width: 0.3px;
  animation: hoverOut 0.2s;
  @include breakpoint('md-and-up') {
    .swiper-slide:hover & {

      transition: opacity 0s;
      opacity: 1;
      animation: hoverIn 0.2s;
    }
  }

}

@keyframes hoverOut {
  0% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(50%, -50%, 0);
    opacity: 0;
  }
}
@keyframes hoverIn {
  0% {
    transform: translate3d(-50%, 50%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0%, 0%, 0);
    opacity: 1;
  }
}
</style>
